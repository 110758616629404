<template>
    <div class="baydin">
        <!-- <nav class="baydin__nav text-center pt-3 pb-3 mb-3 shadow-sm">
            <h3 class="fw-bold mb-0">PSS Baydin</h3>
        </nav> -->
        <div class="container  baydin-bg-img">
            <div
                class="col-md-12 col-sm-12 col-12 pb-20"
                v-for="(baydin, index) in baydins"
                :key="index"
            >
                <baydin-card
                    :title="baydin.title"
                    :routeName="baydin.routeName"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaydinCard from '../../components/baydin/BaydinCard';

export default {
    components: {
        'baydin-card': BaydinCard,
    },
    data() {
        return {
            baydins: [
                {
                    id: 1,
                    title: 'Demoniceye',
                    routeName: 'DemoniceyeAll',
                },
                {
                    id: 3,
                    title: 'Lottery Fotune',
                    routeName: 'Lottery',
                },
                {
                    id: 4,
                    title: 'Greate Board',
                    routeName: 'GreatBoard',
                },
                {
                    id: 5,
                    title: 'Number Fotune',
                    routeName: 'NumberFotune',
                },
            ],
        };
    },
};
</script>
<style lang="scss">
.baydin-bg-img {
    background-image: url('~@/assets/img/baydin/horoscope.jpg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
}

.pb-20 {
    padding-bottom: 20px;
}
</style>
