<template>
    <div class="card mb-3" @click="goToMaeMal(routeName)">
        <div class="card-body">{{ getBurmeseName(routeName) }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            meta: {
                DemoniceyeAll: 'နတ်မျက်စိ ဗေဒင်',
                Tarot: 'တားရော့ ဗေဒင်',
                Lottery: 'ထီကံစမ်း',
                GreatBoard: 'မဟာဘုတ်',
                NumberFotune: 'ဂဏန်း ဗေဒင်',
            },
        };
    },
    props: {
        title: {
            type: String,
            require: true,
        },
        routeName: {
            type: String,
            require: true,
        },
    },

    methods: {
        goToMaeMal(name) {
            this.$router.push({
                name: name,
            });
        },
        getBurmeseName: function(title) {
            console.log('Debug me');
            return this.$data.meta[title];
        },
    },
};
</script>
